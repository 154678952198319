
import Store from '../store/index.js'
export default{
  // index.html xmmlfastclouder@gmail
  //事件追踪
  event(eventCategory, eventAction) {
    if (window.gtag) {
      try{
        let userinfo = Store.getUserInfo();
      let nickname = userinfo.nickname;
      if(nickname.indexOf("@") != -1){
        nickname = nickname.replace("@","-")
      }
      let data = {
        event_category: eventCategory,
        event_label: nickname,
        value: 1,
        send_to: 'G-9NR0JCZWQ6'
    }
        window.gtag('event', eventAction, data)
      }finally{
        console.log()
      }
    }
  },
  //页面追踪
  // page(page, title, location) {
  //   if (window.gtag) {
  //       window.gtag('config', this.GA_TRACKING_ID, {
  //           page_title: title,
  //           page_path: page,
  //           page_location: location
  //       })
  //   }
  // }
}
	
